import React, { useState, useEffect, useRef, useMemo } from 'react';
import {GatsbyImage, getImage} from "gatsby-plugin-image";
import disableScroll from 'disable-scroll';
import { useIsInViewport } from '../features/IsInViewport';

const IntroIndustryComponent = (props) => {
  const [selectedId, setSelectedId] = useState(1);
  const [activeSlideContent, setActiveSlideContent] = useState(false);
  const [visible, setVisible] = useState(false);
  const isMouseEnter = useRef(false);
  const ref1 = useRef(null)
  const isUp = useRef(false)
  const isInViewport = useIsInViewport(ref1);
  const title = props.title;
  const text = props.text;
  const features = props.features;
  const texts = props.texts;
  const ref = useRef()
  var debounce = true;
  
  const t = (key, vocabulary) => {
    return vocabulary[key] || `{{${key}}}`
  }
  const slideList = features.map((e) => {
    return {id: e.id, Img: e.img, image: e.smallImg, title: t(e.title, texts), subTitle: t(e.text, texts)}
  })
  let delta;
  const counter2 = useRef(selectedId);

  useEffect(() => {
    if(isInViewport){
      setTimeout(()=>{
        setVisible(true)
      },[300])
    }
  })

  const handleScroll = (e) => {
      delta = e.deltaY
      if(isMouseEnter.current){
        if(window.innerWidth > 1200){
          if(counter2.current <= 1 && e.deltaY < 0) {
            disableScroll.off()
          } else if (counter2.current >= 3 && e.deltaY > 0) {
            disableScroll.off()
          } else {
            if(debounce){
              debounce = false
              if (window.scrollY >= ref?.current?.offsetTop - 60 && window.scrollY <= ref?.current?.offsetTop) {
                if(e.deltaY > 0){
                  isUp.current = true
                  setActiveSlideContent(true);
                  setTimeout(()=>{
                    setSelectedId((prevState) =>  {++counter2.current; return prevState + 1});
                    setActiveSlideContent(false);
                  }, 500)
                } else {
                  isUp.current = false
                  setActiveSlideContent(true);
                  setTimeout(()=>{
                    setSelectedId((prevState) =>  {--counter2.current; return prevState - 1})
                    setActiveSlideContent(false);
                  }, 500)
                }
              }
              setTimeout(()=>{debounce = true}, [750])
            }
          }
        } else{
          disableScroll.off()
        }
      } else{
        disableScroll.off()
      }
    }
  const handleSetSelectedId = (id) => {
    if(counter2.current > id){
      isUp.current = false
    } else{
      isUp.current = true
    }
    setActiveSlideContent(true);
    counter2.current = id
    setTimeout(()=>{
      setSelectedId(id)
      setActiveSlideContent(false);
    }, 500)
  }
  const handleMouseEnter = () => {
    isMouseEnter.current = true
  }
  const handleMouseLeave = () => {
    isMouseEnter.current  = false
  }
  const scrollHandler = (e) => {
    if(delta){
      if(isMouseEnter.current){
        if(window.innerWidth > 1200){
          if (window.scrollY >= ref?.current?.offsetTop - 60 && window.scrollY <= ref.current.offsetTop) {
            if(counter2.current <= 1 && delta < 0){
              disableScroll.off()
            } else if (counter2.current >= 3 && delta > 0) {
              disableScroll.off()
            } else {
              disableScroll.on()
            }
          }
        } else{
          disableScroll.off()
        }
      } else{
        disableScroll.off()
      }
    }
  }

  useEffect(() => {
    window.addEventListener('wheel',handleScroll)
    return () => {
      window.removeEventListener('wheel', handleScroll)
  }
  }, [])

  useEffect(() => {
    window.addEventListener('scroll', scrollHandler)
    return () => window.removeEventListener('scroll', scrollHandler);
  }, [])
  return (
    <div className="introIndustrySlide">
      <div className="slideContent">
        <div className="mainContainer gutter60">
          <div className="introIndustrySlideTitleWrapper">
            <h1 className='introIndustrySlideTitle'>{title}</h1>
            <div className="introIndustrySlideSubTitleWrapper">
              <p className="introIndustrySlideSubTitle mb40">
                {text}
              </p>
              <div className="btnHolder">
                <a href='https://app.uploadletters.com/register' className="button introIndustrySlideButton">Get Started <div className="whiteDot"/></a>
              </div>
            </div>
          </div>
          <div className={visible ? "introIndustrySlideContentWrapper slideVisible" : "introIndustrySlideContentWrapper slideNoneVisible"} ref={ref} onMouseEnter={() => handleMouseEnter()} onMouseLeave={() => handleMouseLeave()}>
            {/* <div className={`textPrimary titlePrice mb80 onMobileTitle ${activeSlideContent ? "ActiveAnimation" : "NoneActiveAnimation"}`}>DO IT ALL</div> */}
            <div className={activeSlideContent ? (isUp.current ? "ActiveIntroIndustrySlideContentImgUP" : "ActiveIntroIndustrySlideContentImgDOWN") : 'introIndustrySlideContentImg td6'} ref={ref1}>
              {slideList.map((e)=>{
                if(+e.id === selectedId){
                  const images = getImage(e.Img)
                  return <GatsbyImage className="ContentImg" image={images} alt={'Feature ' + e.id} />
                }
              })}
            </div>
            <div className="introIndustryImages">
              <div className="introIndustrySlideContentList">
                {
                  slideList.map((e)=>{
                    const images = getImage(e.image)
                    const isActive = +e.id === selectedId
                    return( 
                      <div className="introIndustrySlideList">
                        <div onClick={()=>{handleSetSelectedId(+e.id)}} className={isActive ? "smallImageWrapper smallImageWrapperActive" : "smallImageWrapper"}>
                          <div className={isActive ? "smallImageBackground smallImageBackgroundActive" : "smallImageBackground"}/>
                          <GatsbyImage className={isActive ? "ActiveSmallImage" : "SmallImage"} image={images} alt={'Feature ' + e.id}/>
                        </div>
                      </div>
                      )
                  })
                }
              </div>
              <div className={activeSlideContent ? "introIndustrySlideContent ActiveIntroIndustrySlideContent" : "introIndustrySlideContent"}>
                {/* <div className={`textPrimary titlePrice mb80 onDesktopTitle ${activeSlideContent ? "ActiveAnimation" : "NoneActiveAnimation"}`}>DO IT ALL</div> */}
                <div className={activeSlideContent ? "ActiveAnimation" : "NoneActiveAnimation td3"}>
                  {
                    slideList.map((e)=>{
                      if(+e.id === selectedId){
                        return (
                          <div>
                            <div className="introIndustrySlideContentTitle">{e.title}</div>
                            <div className="introIndustrySlideContentSubTitle">{e.subTitle}</div>
                          </div>
                        )
                      } else{
                        return null
                      }
                    }
                    )
                  }
                </div>
                <div className={`btnHolder ${activeSlideContent ? "ActiveAnimation" : "NoneActiveAnimation td4"}`}>
                  <a href='https://app.uploadletters.com/login' className="button introIndustrySlideButton">Login <div className="whiteDot"/></a>
                </div>
              </div>
            </div>
          </div>
          {/* <FeaturesListComponent features={features} texts={texts}/> */}
        </div>
      </div>
    </div>
  )
}

export default IntroIndustryComponent